import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Stack } from './Stack'
import { Control, useFieldArray, useForm, UseFormRegister } from 'react-hook-form'
import { EnvironmentApi, EnvironmentDetail, EnvironmentDetailType, PermissionsResources } from '@smartsupp/dapi-client'
import { EnvironmentsErrorCodes, EnvironmentsErrorMessages, useEnvironments } from '../context/environments'
import { FormInput } from './FormInput'
import { FormSelect } from './FormSelect'
import { Button } from './Button'
import { useServices } from '../context/services'
import { EnvironmentInstancesGrid } from './EnvironmentInstancesGrid'
import { EnvironmentInstanceSelect } from './EnvironmentInstanceSelect'
import { getValidationError, ValidationError } from '../utils/request'
import { useUserPermissions } from '../context/userPermissions'

interface Props {
	defaultValues?: EnvironmentDetail
	onSaved: (environment: EnvironmentDetail) => void
}

export const EnvironmentForm: React.FC<Props> = ({ defaultValues, onSaved }) => {
	const { fetchServices, services } = useServices()

	/**
	 * Merge services with environment instances
	 */
	const formValues = useMemo<FormValues | null>(() => {
		if (!defaultValues || !services) return null

		const instances = defaultValues?.instances || []
		const completeInstances: FormInstance[] = services.map(service => {
			const instance = instances.find(instance => instance.serviceId === service.id)
			return {
				id: instance?.id,
				serviceId: service.id,
				serviceName: service.name,
				image: service.image,
				tag: instance?.tag || service.tag,
				defaultTag: service.tag,
			}
		})
		return {
			...defaultValues,
			instances: completeInstances,
		}
	}, [defaultValues, services])
	
	useEffect(() => {
		fetchServices()
	}, [fetchServices])

	if (!formValues) return <>Fetching form data...</>

	return <Form onSaved={onSaved} defaultValues={formValues} />
}

export const Form: React.FC<{
	defaultValues?: FormValues
	onSaved: (environment: EnvironmentDetail) => void
}> = ({ defaultValues, onSaved }) => {
	const { handleSubmit, register, reset, control, formState, setError } = useForm<FormValues>({
		defaultValues,
		reValidateMode: 'onChange',
	})
	const { createEnvironment, updateEnvironment, updateEnvironmentInstances } = useEnvironments()
	const [submitDisabled, setSubmitDisabled] = useState(false)
	const { hasPermissions } = useUserPermissions()
	const canManageEnvironemnt = useMemo(() => hasPermissions(PermissionsResources.ManageEnvironments), [hasPermissions])

	const handleValidationError = useCallback((error: ValidationError) => {
		switch (error.code) {
			case EnvironmentsErrorCodes.EnvironmentLimitExceeded: 
				alert(EnvironmentsErrorMessages.EnvironmentLimitExceeded)
				break
			case EnvironmentsErrorCodes.InvalidParameters: 
				alert(EnvironmentsErrorMessages.InvalidParameters)
				break
			case EnvironmentsErrorCodes.EnvironmentValidationDomain:
				setError('domain', { message: EnvironmentsErrorMessages.EnvironmentValidationDomain })
				break
			default:
				alert(EnvironmentsErrorMessages.Unknown)
		}
	}, [setError])

	/**
	 * Create new env and handle form effects
	 */
	const handleCreate = useCallback(async (values: EnvironmentApi.CreateBody) => {
		try {
			const environment = await createEnvironment(values)
			onSaved(environment)
		} catch (err) {
			const validationError = getValidationError(err)
			if (validationError) {
				handleValidationError(validationError)
			}
			setSubmitDisabled(false)
		}
	}, [createEnvironment, setSubmitDisabled, handleValidationError])

	/**
	 * Update env and handle form effects
	 */
	const handleUpdate = useCallback(async (values: EnvironmentApi.PatchBody) => {
		try {
			const environment = canManageEnvironemnt
				? await updateEnvironment(values)
				: await updateEnvironmentInstances(values)
			reset(values)
			onSaved(environment)
		} catch (err) {
			const validationError = getValidationError(err)
			if (validationError) {
				handleValidationError(validationError)
			}
			alert((err as Error).message)
		} finally {
			setSubmitDisabled(false)
		}
	}, [updateEnvironment, reset, setSubmitDisabled, handleValidationError, canManageEnvironemnt])

	/**
	 * Handle form submit
	 */
	const onSubmit = useCallback((values: EnvironmentApi.CreateBody) => {
		setSubmitDisabled(true)

		if (defaultValues?.id) {
			handleUpdate({
				...defaultValues,
				...values,
			})
		} else {
			handleCreate({
				...values,
				type: EnvironmentDetailType.Development, // TODO there are currently only ***development** envs
			})
		}
	}, [handleCreate, handleUpdate, setSubmitDisabled])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack direction='vertical' gap={2}>
				<Button
					text='Save'
					submit
					disabled={submitDisabled}
				/>

				<Stack direction='vertical' gap={0.5}>
					<FormInput
						register={register(
							'name',
							{
								required: 'Fill in the environment name.',
								validate: (val) => val.match(/^[a-z0-9-]+$/)
									? true
									: 'Allowed characters: a-z, 0-9 and -.',
							}
						)}
						disabled={!canManageEnvironemnt}
						errors={formState.errors}
						label='Name of environment'
						type='text'
					/>
					<FormInput
						register={register(
							'domain',
							{
								required: 'Fill in the environment domain.',
								validate: (val) => val.match(/^[a-z0-9-.]+$/)
									? true
									: 'Allowed characters: a-z, 0-9 and -.',
							}
						)}
						disabled={!canManageEnvironemnt}
						errors={formState.errors}
						label='Environment domain'
						type='text'
					/>
					<FormInput
						register={register('description', { required: 'Fill in the environment description.' })}
						errors={formState.errors}
						label='Description'
						type='text'
						disabled={!canManageEnvironemnt}
					/>
					<FormInput
						register={register('url', {
							required: 'Fill in the environment URL.',
							validate: (val) => val && val.match(/https?:\/\/[^.]+\..+/) 
								? true
								: 'Invalid URL',
						})}
						errors={formState.errors}
						label='Url'
						type='text'
						disabled={!canManageEnvironemnt}
					/>
					<FormSelect
						register={register('type', { required: false })}
						label='Type'
						disabled
						options={[{ value: EnvironmentDetailType.Development, title: 'development' }]}
					/>
				</Stack>

				<Instances
					register={register}
					control={control}
				/>
			</Stack>
		</form>
	)
}

const Instances: React.FC<{
	register: UseFormRegister<FormValues>
	control: Control<FormValues>
}> = ({ register, control }) => {
	const { fields } = useFieldArray({
		control,
		name: 'instances',
	})

	return (
		<EnvironmentInstancesGrid>
			{fields.map((instance, index) => {
				return (
					<EnvironmentInstanceSelect
						key={instance.serviceId}
						register={register(`instances.${index}.tag`)}
						instance={instance}
					/>
				)
			})}
		</EnvironmentInstancesGrid>
	)
}

export type FormValues = Omit<EnvironmentDetail, 'instances'> & {
	instances: FormInstance[]
}

export type FormInstance = {
	id?: number
	serviceId: number
	serviceName: string
	image: string
	tag: string
	defaultTag: string
}
