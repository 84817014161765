import React from 'react'
import { NavLink } from 'react-router-dom'
import { VERSION } from '../constants'
import { useApi } from '../context/api'
import { useUser } from '../context/user'
import { Stack } from './Stack'
import LogoSvg from '../images/logo.svg'
import LogoutPng from '../images/logout.png'
import EnvironmentsPng from '../images/environments.png'
import ServicesPng from '../images/services.png'
import UsersPng from '../images/users.png'
import SettingsPng from '../images/settings.png'
import { navigationPaths } from '../context/navigation'
import { useUserPermissions } from '../context/userPermissions'
import { PermissionsResources } from '@smartsupp/dapi-client'
import { UserAvatar } from './UserAvatar'

export const SidePanel: React.FC = () => {
	return (
		<div className="SidePanel">
			<div className="SidePanel__logo">
				<img className="SidePanel__logo-image" src={LogoSvg}/>
				<span className="SidePanel__logo-text">Deployment<br/>Development<br/>Tool</span>
			</div>
			<UserTab/>
			<NavigationTab/>
			<FooterTab/>
		</div>
	)
}

const UserTab: React.FC = () => {
	const { logged, data, logout } = useUser()

	return (	
		<div className="SidePanel__user">
			{
				logged && (
					<Stack direction='vertical'>
						<Stack direction='horizontal' align='bottom'>
							<UserAvatar/>
							<span className="SidePanel__user-title">{data?.name}</span>
						</Stack>
						<div className='SidePanel__user-actions'>
							<UserTabAction
								action={logout}
								icon={LogoutPng}
								text='Logout'
							/>
						</div>
					</Stack>
				)
			}
		</div>
	)
}

const UserTabAction: React.FC<{
	action: () => void
	icon: string
	text: string
}> = ({ action, icon, text }) => {
	return (	
		<button
			className='SidePanel__user-action'
			onClick={() => action()}
		>
			<Stack direction='horizontal' gap={0} align='center'>
				<img src={icon} className='SidePanel__user-action-icon'/>
				<span className='SidePanel__user-action-text'>{text}</span>
			</Stack>
		</button>
	)
}

const NavigationTab: React.FC = () => {
	const { hasPermissions } = useUserPermissions()

	return (
		<div className='SidePanel__navigation'>
			{hasPermissions(PermissionsResources.ViewEnvironments) && (
				<NavigationLink
					to={navigationPaths.environments}
					image={EnvironmentsPng}
					text='Environments'
				/>
			)}
			{hasPermissions(PermissionsResources.ViewServices) && (
				<NavigationLink
					to={navigationPaths.services}
					image={ServicesPng}
					text='Services'
				/>
			)}
			{hasPermissions(PermissionsResources.ViewUsers) && (
				<NavigationLink
					to={navigationPaths.users}
					image={UsersPng}
					text='Users'
				/>
			)}
			{hasPermissions(PermissionsResources.ManageSettings) && (
				<NavigationLink
					to={navigationPaths.settings}
					image={SettingsPng}
					text='Settings'
				/>
			)}
		</div>
	)
}

const NavigationLink: React.FC<{ to: string, text: string, image: string }> = ({ to, image, text }) => (
	<NavLink to={to} activeClassName='active' className='SidePanel__navigation-link'>
		<Stack direction='horizontal' gap={0} align='center'>
			<img src={image} className='SidePanel__navigation-icon'/>
			<span className='SidePanel__navigation-text'>{text}</span>
		</Stack>
	</NavLink>
)

const FooterTab: React.FC = () => {
	const { status } = useApi()
	return (
		<div className="SidePanel__footer">
			App version: {VERSION}
			<br/>
			Api version: {status?.apiVersion}
		</div>
	)
}
