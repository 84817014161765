import { ParsedQuery } from 'query-string'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AppTitle } from '../components/AppTitle'
import { Button } from '../components/Button'
import { ERROR_RELOAD_TIMEOUT } from '../constants'

enum ErrorCodes {
  'user.login.google' = 'user.login.google',
  'user.login.github' = 'user.login.github',
  unknown = 'unknown',
}

export const ErrorPage: React.FC<{ errorCode: string, queryParams: ParsedQuery<string> }> = ({ errorCode, queryParams }) => {
  const [reloadDisabled, setReloadDisabled] = useState(true)

  const code = useMemo(() => {
    if (Object.values(ErrorCodes).includes(errorCode as ErrorCodes)) {
      return errorCode
    }
    return ErrorCodes.unknown
  }, [errorCode])

  const getErrorMessage = useCallback((): string => {
    switch (code) {
      case ErrorCodes['user.login.google']:
        return 'Google login failed'
      case ErrorCodes['user.login.github']:
        return 'GitHub login failed'
      case ErrorCodes.unknown:
      default:
        return 'An unknown error has occurred'
    }
  }, [code])

  const reloadApp = useCallback((): void => {
    window.location.href = window.location.href.split('?')[0]
  }, [window])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReloadDisabled(false)
    }, ERROR_RELOAD_TIMEOUT)
    return () => {
      clearInterval(timeout)
    }
  }, [setReloadDisabled])

  return (
    <div className='ErrorPage'>
      <AppTitle subtitle='Error'/>

      <div className='ErrorPage__center'>
        <div className='ErrorPage__info'>
          <span className='ErrorPage__message'>{getErrorMessage()}</span>
          <span className='ErrorPage__code'>{queryParams?.statusCode || 'no code'}</span>
        </div>
        <Button
          text='Reload page'
          disabled={reloadDisabled}
          onClick={() => reloadApp()}
        />
      </div>
    </div>
  )
}

