import React from 'react'
import cc from 'classcat'

export interface StackProps {
	direction?: 'vertical' | 'horizontal'
	gap?: number
	align?: 'center' | 'bottom' | 'top'
}

export const Stack: React.FC<StackProps> = ({ children, direction, gap, align }) => {
	return (
		<div 
			className={cc([
				'Stack',
				{
					'Stack--vertical': !direction || direction === 'vertical',
					'Stack--horizontal': direction === 'horizontal',
					'Stack--center': align === 'center',
					'Stack--bottom': align === 'bottom',
					'Stack--top': align === 'top',
				}
			])}
			style={{
				gap: gap ? `${gap}rem` : undefined 
			}}
		>
			{ children }
		</div>
	)
}
