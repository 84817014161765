import React, { useEffect } from 'react'
import { Stack } from '../components/Stack'
import { Button } from '../components/Button'
import { useEnvironments } from '../context/environments'
import { Link, useHistory } from 'react-router-dom'
import { navigationPaths } from '../context/navigation'
import { Badge, BadgeStyle } from '../components/Badge'
import { AppTitle } from '../components/AppTitle'
import { EnvironmentSimple, EnvironmentSimpleState, PermissionsResources } from '@smartsupp/dapi-client'
import { useUserPermissions } from '../context/userPermissions'

export const EnvironmentsPage: React.FC = () => {
  const { fetchEnvironments, watchEnvironments, stopWatchingEnvironments } = useEnvironments()
  const { hasPermissions } = useUserPermissions()
  const history = useHistory()

  if (!hasPermissions(PermissionsResources.ViewEnvironments)) {
    history.replace(navigationPaths.lobby)
    return null
  }

  /**
   * Watching for changes
   */
  useEffect(() => {
    fetchEnvironments()
    const watchId = watchEnvironments()
    return () => {
      stopWatchingEnvironments(watchId)
    }
  }, [fetchEnvironments, watchEnvironments, stopWatchingEnvironments ])

  return (
      <>
        <AppTitle subtitle='Environments'/>
        <Stack direction='vertical' gap={2}>

          <h2 className='Content__title'>Development Environments</h2>

          {hasPermissions(PermissionsResources.ManageEnvironments) ? (
            <Link to={navigationPaths.environmentCreate} className='Button'>Create Environment</Link>
          ) : (
            <Button text='Create Environment' disabled />
          )}

          <ActiveList/>
          <InactiveList/>

        </Stack>
      </>
  )
}

const ActiveList: React.FC = () => {
  const { activeItems } = useEnvironments()

  return (
    <Stack direction='vertical'>
      <span>Active Environments ({activeItems.length})</span>
      <ListTable
        items={activeItems}
      />
    </Stack>
  )
}

const InactiveList: React.FC = () => {
  const { inactiveItems } = useEnvironments()

  return (
    <Stack direction='vertical'>
      <span>Inactive Environments ({inactiveItems.length})</span>
      <ListTable
        items={inactiveItems}
      />
    </Stack>
  )
}

const ListTable: React.FC<{ items: EnvironmentSimple[] }> = ({ items }) => {
  const history = useHistory()

  return (
    <table className='Table'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>State</th>
          <th>Author</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {
          items.map(item => (
            <tr
              key={item.id}
              className='Table__row--clickable'
              onClick={() => history.push(navigationPaths.environmentDetail(item.id))}
            >
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>
                <Badge
                  text={item.state}
                  style={item.state === EnvironmentSimpleState.Up ? BadgeStyle.success : BadgeStyle.danger}
                />
              </td>
              <td>{item.creator.name}</td>
              <td className='Table__cell--right'>
                <Button text='View' style='slim'/>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}
