import { PermissionsResources, ServiceDetail } from '@smartsupp/dapi-client'
import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { AppTitle } from '../components/AppTitle'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { Button } from '../components/Button'
import { LoaderInline } from '../components/Loader'
import { ServiceForm } from '../components/ServiceForm'
import { Stack } from '../components/Stack'
import { navigationPaths } from '../context/navigation'
import { useServices } from '../context/services'
import { useUserPermissions } from '../context/userPermissions'

export const ServicesDetailPage: React.FC = () => {
  const { params } = useRouteMatch<{ id: string }>()
  const { hasPermissions } = useUserPermissions()
  const { fetchServiceDetail } = useServices()
  const history = useHistory()
  const id = Number(params.id)
  const [initialized, setInitialized] = useState(false)
  const [editing, setEditing] = useState(false)
  const [detail, setDetail] = useState<ServiceDetail | null>(null)

  if (!hasPermissions(PermissionsResources.ViewServices)) {
    history.replace(navigationPaths.lobby)
    return null
  }

  useEffect(() => {
    if (Number.isNaN(id)) {
      setInitialized(true)
      return
    }
    if (!initialized && !detail) {
      fetchServiceDetail(id)
        .then(detail => {
          setDetail(detail)
          setInitialized(true)
        })
    } 
  }, [id, detail, fetchServiceDetail, setDetail, setInitialized])

  /**
   * Called when detail has been fetched fort the first time, re-fetched with polling of updated with form
   */
   const detailChanged = useCallback((newDetail: ServiceDetail | null) => {
    if (!newDetail) {
      history.push(navigationPaths.services)
      return
    }
    setDetail(newDetail)
    setEditing(false)
  }, [setDetail, setEditing])

  if (!initialized) {
    return <LoaderInline reason='Loading service detail..'/> 
  }

  if (!detail) {
    return <Redirect to={navigationPaths.services} />
  }

  return (
      <>
        <AppTitle subtitle={`Service "${detail.name}"`}/>

        <Stack direction='vertical' gap={2}>
          <Breadcrumbs items={[
            { title: 'Services', navigateTo: navigationPaths.services },
            { title: detail.name },
          ]}/>
          
          {editing && (
            <Button
              text='Discard changes'
              onClick={() => setEditing(false)}
            />
          )}
          {!editing && hasPermissions(PermissionsResources.ManageServices) && (
            <Button
              text='Edit'
              onClick={() => setEditing(true)}
            />
          )}
          
          {editing ? (
            <ServiceForm
              onSaved={detail => detailChanged(detail)}
              defaultValues={detail}
            />
          ) : (
            <Detail detail={detail}/>
          )}
  
        </Stack>
      </>
  )
}

const Detail: React.FC<{ detail: ServiceDetail }> = ({ detail }) => {
  return (
    <Stack>
    <span>Info</span>

    <table className='Table'>
      <tbody>
          <tr>
            <th>ID</th>
            <td>{detail.id}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{detail.name}</td>
          </tr>
          <tr>
            <th>Image</th>
            <td>{detail.image}</td>
          </tr>
          <tr>
            <th>Default tag</th>
            <td>{detail.tag}</td>
          </tr>
          <tr>
            <th>Mustache placeholder</th>
            <td>{detail.mustache}</td>
          </tr>
        </tbody>
    </table>
  </Stack>
  )
}
