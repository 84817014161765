import React, { useCallback } from 'react'
import cc from 'classcat'
import { UseFormRegisterReturn } from 'react-hook-form'
import { Stack } from './Stack'

export interface FormSelectProps {
	register: UseFormRegisterReturn
	label: string
	options: { value: string, title: string }[]
	disabled?: boolean
	onFocus?: () => void
	inline?: boolean
}

export const FormSelect: React.FC<FormSelectProps> = (props) => {
	return (
		<div>
			{props.inline ? (
				<Stack direction='horizontal' align='center'>
					<Select {...props} />
				</Stack>
			) : (
				<div>
					<Select {...props} />
				</div>
			)}
		</div>
	)
}

const Select: React.FC<FormSelectProps> = ({ register, label, options, disabled, onFocus }) => {
	const handleOnFocus = useCallback(() => {
		if (onFocus) {
			onFocus()
		}
	}, [onFocus])

	return (
		<>
			<label className='FormLabel'>{label}</label>
			<select 
				{...register}
				className={cc([
					'FormSelect',
				])}
				disabled={disabled}
				onFocus={() => handleOnFocus()}
			>
				{
					options.map(option => (
						<option key={option.value} value={option.value}>{option.title}</option>
					))
				}
			</select>
		</>
	)
}
