import { REQUESTS_DEBOUNCE_INTERVAL } from '../constants'

export class Debounce {
	private timeout?: NodeJS.Timeout = undefined
	/** State for **firstAndLastOccurrence** mode */
	private firstAndLastOccurrence = {
		invokeAtEnd: false,
		coolDownTimeout: undefined as (NodeJS.Timeout | undefined)
	}
	
	/**
	 * @param callback Callback function invoked with regards to selected mode
	 * @param interval Interval in milliseconds
	 * @param mode Debouncing mode defining when should be the callback triggered
	 */
	constructor(
		private callback: DebounceCallback,
		private interval: number,
		private mode: DebounceMode = DebounceMode.firstAndLastOccurrence,
	) {
	}

	call(): void {
		switch (this.mode) {
			default:
			case DebounceMode.firstOccurrence:
				if (!this.timeout) {
					this.startTimeout()
					this.invokeCallback()
				}
				break
			
			case DebounceMode.lastOccurrence:
				if (!this.timeout) {
					this.startTimeout(() => {
						this.invokeCallback()
					})
				}
				break

			/**
			 * **Lifecycle**
			 * - if no coolDown is running => invoke first occurrence
			 * - if coolDown is running => do NOT invoke first occurrence
			 * - if there is a call when the main timeout is running = mark last occurrence to be invoked
			 * - if there is a call when the coolDown is running = mark last occurrence to be invoked, remove coolDown so it could be set up again
			 */
			case DebounceMode.firstAndLastOccurrence:
				if (!this.timeout) {
					
					if (this.firstAndLastOccurrence.coolDownTimeout) {
						// New call in the interval and the coolDown is still running
						// => invoke last occurrence
						this.firstAndLastOccurrence.invokeAtEnd = true
						// => remove coolDown so it could be set up again
						clearTimeout(this.firstAndLastOccurrence.coolDownTimeout)
						this.firstAndLastOccurrence.coolDownTimeout = undefined
					} else {
						// New call in the interval and the coolDown is NOT running
						// => there is no need to trigger lastOccurrence yet
						this.firstAndLastOccurrence.invokeAtEnd = false
						// => invoke first occurrence
						this.invokeCallback()
					}
					
					this.startTimeout(() => {
						if (this.firstAndLastOccurrence.invokeAtEnd) {
							this.invokeCallback()
							this.firstAndLastOccurrence.coolDownTimeout = setTimeout(() => {
								this.firstAndLastOccurrence.coolDownTimeout = undefined
							}, REQUESTS_DEBOUNCE_INTERVAL)
						}
					})
				} else {
					this.firstAndLastOccurrence.invokeAtEnd = true
				}
				break
		}
	}

	clear(): void {
		if (this.timeout) {
			clearTimeout(this.timeout)
			this.timeout = undefined
		}
	}

	private startTimeout(cb?: VoidCallback) {
		this.timeout = setTimeout(() => {
			if (cb) {
				cb()
			}
			this.timeout = undefined
		}, this.interval)
	}

	private invokeCallback() {
		this.callback()
	}
}

export type DebounceCallback = () => void
export enum DebounceMode {
	firstOccurrence,
	lastOccurrence,
	firstAndLastOccurrence,
}
export type CancelDebounce = () => void

type VoidCallback = () => void
