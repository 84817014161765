import React, { createContext, useCallback, useContext } from 'react'
import { useApi } from './api'
import { DockerImageTag } from '@smartsupp/dapi-client'
import { initContextFn as fn } from '../utils/context'

const contextDefaults = {
	fetchImageTags: fn<(imageName: string) => Promise<DockerImageTag[]>>(),
}
const context = createContext(contextDefaults)
export type DockerImages = typeof contextDefaults

export const  DockerImagesProvider: React.FC = ({ children }) => {
	const { getApiClient } = useApi()

	const fetchImageTags = useCallback(async (imageName: string): Promise<DockerImageTag[]> => {
		return await getApiClient().docker.imageTags(imageName)

	}, [getApiClient])

	return (
		<context.Provider value={{ fetchImageTags }}>
			{ children }
		</context.Provider>
	)
}

export const useDockerImages = (): DockerImages => useContext(context)
