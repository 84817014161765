import React, { createRef, useCallback, useEffect, useMemo } from 'react'
import { formatElapsedTime, getDate } from '../utils/date'

export interface Props {
	from?: Date | string | null
	to?: Date | string | null
}

const REFRESH_INTERVAL = 1000

export const ElapsedTime: React.FC<Props> = ({ from, to }) => {
	const ref = createRef<HTMLSpanElement>()
	const initalTime = useMemo(() => {
		if (!from) {
			return '-'
		}
		return formatElapsedTime(
			getDate(from),
			to ? getDate(to) : undefined,
		)
	}, [from, to])

	const animate = useCallback((fromDate: Date, animationRef: { frameId: number }): void => {
		const span = ref.current

		let lastRendered = Date.now()
		let renderEnded = true
		if (!span) {
			return
		}

		const step = () => {
			if (renderEnded && Date.now() - lastRendered >= REFRESH_INTERVAL) {
				renderEnded = false
				span.innerHTML = formatElapsedTime(
					fromDate,
					new Date()
				)
				lastRendered = Date.now()
				renderEnded = true
			}
			
			animationRef.frameId = requestAnimationFrame(step)
		}

		animationRef.frameId = requestAnimationFrame(step)
	}, [from, ref])

	useEffect(() => {
		if (from && !to) {
			const animationRef = { frameId: 0 }
			animate(getDate(from), animationRef)
			return () => {
				cancelAnimationFrame(animationRef.frameId)
			}
		}
	}, [from, to])

	return (
		<span ref={ref}>{initalTime}</span>
	)
}
