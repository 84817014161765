import React, { createContext, useCallback, useContext, useState } from 'react'
import { PatchUserRole, UsersListItem } from '@smartsupp/dapi-client'
import { initContextFn as fn, VoidFn } from '../utils/context'
import { useApi } from './api'

const contextDefaults = {
	users: [] as UsersListItem[],
	fetchUsers: fn<VoidFn>(),
	updateRole: fn<(id: number, role: PatchUserRole) => void>(),
}
const context = createContext(contextDefaults)
export type Users = typeof contextDefaults

export const UsersProvider: React.FC = ({ children }) => {
	const [users, setUsers] = useState<UsersListItem[]>([])
	const { getApiClient } = useApi()

	const fetchUsers = useCallback(async () => {
		const users = await getApiClient().users.list()
		setUsers(users)
	}, [getApiClient, setUsers])

	const updateRole = useCallback(async (id, role) => {
		const user = await getApiClient().users.patch(id, { role })
		setUsers(users => users.map(_user => {
			return _user.id === id
				? user
				: _user
		}))
	}, [getApiClient, setUsers])

	return (
		<context.Provider value={{ users, fetchUsers, updateRole }}>
			{children}
		</context.Provider>
	)
}

export const useUsers = (): Users => useContext(context)
