import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { PermissionsResources } from '@smartsupp/dapi-client'
import { useUser } from './user'
import { useApi } from './api'
import { getUserResources } from '../utils/user'
import { initContextFn as fn } from '../utils/context'

const contextDefualts = {
	initialized: false,
	hasPermissions: fn<(permissions: PermissionsResources | PermissionsResources[]) => boolean>()
}
const context = createContext(contextDefualts)
export type UserPermissions = typeof contextDefualts

export const UserPermissionsProvider: React.FC = ({ children }) => {
	const { logged, data } = useUser()
	const { getApiClient } = useApi()
	const [userResources, setUserResources] = useState<PermissionsResources[]>()
	const [initialized, setInitialized] = useState(false)

	// Fetch user permission resources
	useEffect(() => {
		if (initialized || !data || !getApiClient()) return

		getApiClient().permissions.getRoleResources()
			.then(roleResources => {
				const userResources = getUserResources(data.role, roleResources)
				setUserResources(userResources)
				setInitialized(true)
			})
	}, [userResources, data, initialized, getApiClient, setUserResources, setInitialized])

	const hasPermissions = useCallback<UserPermissions['hasPermissions']>((permissions) => {
		if (!logged || !data || !userResources) {
			return false
		}
		const required = Array.isArray(permissions) ? permissions : [permissions]
		return required.every(resource => userResources.includes(resource))
	}, [logged, data, userResources])

	return (
		<context.Provider value={{ initialized, hasPermissions }}>
			{children}
		</context.Provider>
	)
}

export const useUserPermissions = (): UserPermissions => useContext(context)
