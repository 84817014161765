import { StatusCodes } from 'http-status-codes'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useEffect } from 'react'
import { ApiEndpoints, useApi } from './api'
import { useSettings } from './settings'
import { CurrentUser, CurrentUserRole, UserCredentialType } from '@smartsupp/dapi-client'
import { initContextFn as fn, VoidFn } from '../utils/context'
import { AxiosAdapter } from '@openapi-client/adapter-axios'

const contextDefaults = {
	initialized: false,
	logged: false,
	data: undefined as UserData | undefined,
	loginExpired: false,
	logout: fn<VoidFn>(),
	googleLoginRedirect: fn<VoidFn>(),
	githubLoginRedirect: fn<VoidFn>(),
}
const context = createContext(contextDefaults)
export type User = typeof contextDefaults

export const UserProvider: React.FC = ({ children }) => {
	const [initialized, setInitialized] = useState<boolean>(false)
	const [logged, setLogged] = useState<boolean>(false)
	const [data, setData] = useState<UserData>()
	const [loginExpired, setLoginExpired] = useState(false)
	const { getSessionId, getApiClient, clearSessionId } = useApi()
	const { apiUrl } = useSettings()

	const logout = useCallback(() => {
		clearSessionId()
		logoutRedirect()
	}, [apiUrl, setData, setLogged])

	const extractUserDataFromCredentials = useCallback((rawUser: CurrentUser): Omit<UserData, 'id'> | null => {
		if (!rawUser.credentials) {
			return null
		}
		const googleCredentials = rawUser.credentials
			.find(item => item.type === UserCredentialType.Google) as RawUserCredentials
		if (!googleCredentials) {
			return null
		}
		return {
			name: googleCredentials.data.name,
			thumbnail: googleCredentials.data.picture,
			role: rawUser.role,
		}
	}, [])

	const fetchUser = useCallback(async (): Promise<boolean> => {
		const rawUser = await getApiClient().user.get({
			validateStatus: (status: number) => [StatusCodes.OK, StatusCodes.NO_CONTENT, StatusCodes.UNAUTHORIZED].includes(status)
		})
		if (rawUser?.id) {
			const credentialsData = extractUserDataFromCredentials(rawUser)
			if (!credentialsData) {
				return false
			}
			setData({
				id: rawUser.id,
				...credentialsData,
			})
			return true
		}
		return false
	}, [getApiClient, setData])

	const logoutRedirect = useCallback(() => {
		window.location.href = `${apiUrl}${ApiEndpoints.logout}`
	}, [apiUrl])

	const googleLoginRedirect = useCallback(() => {
		window.location.href = `${apiUrl}${ApiEndpoints.googleLogin}`
	}, [apiUrl])

	const githubLoginRedirect = useCallback(() => {
		window.location.href = `${apiUrl}${ApiEndpoints.githubLogin}`
	}, [apiUrl])

	// User initialization
	useEffect(() => {
		(async () => {
			if (!getSessionId()) {
				setLogged(false)
				setInitialized(true)
			} else {
				const logged = await fetchUser()
				setLogged(logged)
				setInitialized(true)
			}
		})()

	}, [getSessionId, setInitialized])

	// Register axios interceptor: unauthorized request => expired login
	useEffect(() => {
		const adapter = getApiClient().adapter as AxiosAdapter
		adapter.axios.interceptors.response.use(undefined, (error) => {
			if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
				setLoginExpired(true)
			}
			return null
		})
	}, [getApiClient])

	return (
		<context.Provider value={{ initialized, logged, data, loginExpired, logout, googleLoginRedirect, githubLoginRedirect }}>
			{children}
		</context.Provider>
	)
}

export const useUser = (): User => useContext(context)

// TODO DAPI client type is incomplete
interface RawUserCredentials {
	id: number
	type: UserCredentialType
	createdAt: string
	credentialId: string
	updatedAt: string
	userId: number
	data: {
		email: string
		family_name: string
		given_name: string
		hd: string //domain
		id: string
		locale: string
		name: string
		picture: string
		verified_email: boolean
	}
}
export interface UserData {
	id: number
	name: string
	thumbnail: string
	role: CurrentUserRole
}
