import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'

function getAxiosValidationError(error: unknown): AxiosError | null {
	if (
		axios.isAxiosError(error) &&
		error.response?.status === StatusCodes.UNPROCESSABLE_ENTITY
	) {
		return error
	}
	return null
}

export function getValidationError(error: unknown): ValidationError | null {
	const axiosError = getAxiosValidationError(error)
	let message, code

	if (axiosError) {
		message = axiosError.message
		code = axiosError.code
	} else if (
		typeof error === 'object' &&
		error &&
		typeof (error as ValidationError).message === 'string'
	) {
		message = (error as ValidationError).message
		code = (error as ValidationError).code || undefined
	} else {
		message = 'unknown error'
	}

	return { message, code }
}

export interface ValidationError {
	message: string
	code?: string
}
