import { AppSettings as SpecAppSettings } from '@smartsupp/dapi-client'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { initContextFn as fn, VoidFn } from '../utils/context'
import { useApi } from './api'

const contextDefaults = {
	appSettings: undefined as SpecAppSettings | undefined,
	fetchSettings: fn<VoidFn>(),
}
const context = createContext(contextDefaults)
export type AppSettings = typeof contextDefaults

export const  AppSettingsProvider: React.FC = ({ children }) => {
	const { getApiClient } = useApi()
	const [appSettings, setAppSettings] = useState<SpecAppSettings | undefined>()

	const fetchSettings = useCallback<AppSettings['fetchSettings']>(async () => {
		const settings = await getApiClient().settings.get()
		setAppSettings(settings)
	}, [getApiClient, setAppSettings])

	return (
		<context.Provider value={{ appSettings, fetchSettings }}>
			{ children }
		</context.Provider>
	)
}

export const useAppSettings = (): AppSettings => useContext(context)
