import React, { createContext, useCallback, useContext, useState } from 'react'
import { useApi } from './api'
import { 
	EnvironmentApi,
	EnvironmentDetail,
	EnvironmentInstance,
	EnvironmentSimple,
	EnvironmentSimpleState,
	InstancesApi,
	ResponseOk,
	ServiceSimple
} from '@smartsupp/dapi-client'
import { useSocket } from './socket'
import { useServices } from './services'
import { filterOutEmptyFn } from '../utils/filter'
import { Dto } from '@smartsupp/dapi-client-dto'
import { Debounce } from '../utils/debounce'
import { REQUESTS_DEBOUNCE_INTERVAL } from '../constants'
import { initContextFn as fn } from '../utils/context'
import { sortObjectFn } from '../utils/sort'

const contextDefaults = {
	activeItems: [] as EnvironmentSimple[],
	inactiveItems: [] as  EnvironmentSimple[],
	fetchEnvironments: fn<() => void>(),
	createEnvironment: fn<(environment: EnvironmentApi.CreateBody) => Promise<EnvironmentDetail>>(),
	updateEnvironment: fn<(environment: EnvironmentApi.PatchBody) => Promise<EnvironmentDetail>>(),
	fetchDetail: fn<(id: number) => Promise<EnvironmentDetail | null>>(),
	startEnvironment: fn<(id: number) => Promise<ResponseOk>>(),
	stopEnvironment: fn<(id: number) => Promise<ResponseOk>>(),
	restartEnvironment: fn<(id: number) => Promise<ResponseOk>>(),
	deleteEnvironment: fn<(id: number) => Promise<ResponseOk>>(),
	watchEnvironments: fn<() => string>(),
	stopWatchingEnvironments: fn<(watchId: string) => void>(),
	watchDetail: fn<(detailId: number, onDetail: (detail: EnvironmentDetail | null) => void) => string>(),
	stopWatchingDetail: fn<(watchId: string) => void>(),
	mapInstances: fn<(instances: EnvironmentInstance[], services: ServiceSimple[], namePhrase?: string) => MappedInstances[]>(),
	updateEnvironmentInstances: fn<(data: InstancesApi.PatchBody) => Promise<EnvironmentDetail>>(),
	restartEnvironmentInstance: fn<(envId: number, instanceId: number) => Promise<ResponseOk>>(),
}
const context = createContext(contextDefaults)
export type Environments = typeof contextDefaults

export const EnvironmentsProvider: React.FC = ({ children }) => {
	const [activeItems, setActiveItems] = useState<EnvironmentSimple[]>([])
	const [inactiveItems, setInactiveItems] = useState<EnvironmentSimple[]>([])
	const { getApiClient } = useApi()
	const { services, fetchServices } = useServices()
	const socket = useSocket()

	const fetchEnvironments = useCallback<Environments['fetchEnvironments']>(async () => {
		const items = await getApiClient().environment.list()
		const { active, inactive } = items.reduce((acc, item) => {
			const activeStates: string[] = [EnvironmentSimpleState.Up, EnvironmentSimpleState.Starting, EnvironmentSimpleState.Partial]
			if (activeStates.includes(item.state)) {
				acc.active.push(item)
			} else {
				acc.inactive.push(item)
			}
			return acc
		}, { active: [] as EnvironmentSimple[], inactive: [] as EnvironmentSimple[] })

		setActiveItems(active)
		setInactiveItems(inactive)
	}, [getApiClient, setActiveItems, setInactiveItems])

	const createEnvironment = useCallback<Environments['createEnvironment']>(async (environment) => {
		return await getApiClient().environment.create(environment)
	}, [getApiClient])

	const updateEnvironment = useCallback<Environments['updateEnvironment']>(async (environment) => {
		return await getApiClient().environment.patch(environment)
	}, [getApiClient])

	const fetchDetail = useCallback<Environments['fetchDetail']>(async (id) => {
		return await getApiClient().environment.get(id)
	}, [getApiClient])

	const startEnvironment = useCallback<Environments['startEnvironment']>(async (id) => {
		return await getApiClient().environment.start(id)
	}, [getApiClient])

	const stopEnvironment = useCallback<Environments['stopEnvironment']>(async (id) => {
		return await getApiClient().environment.stop(id)
	}, [getApiClient])

	const restartEnvironment = useCallback<Environments['restartEnvironment']>(async (id) => {
		return await getApiClient().environment.restart(id)
	}, [getApiClient])

	const deleteEnvironment = useCallback<Environments['deleteEnvironment']>(async (id) => {
		return await getApiClient().environment.delete(id)
	}, [getApiClient])


	const watchEnvironments = useCallback<Environments['watchEnvironments']>(() => {
		const debounce = new Debounce(
			() => fetchEnvironments(),
			REQUESTS_DEBOUNCE_INTERVAL,
		)
		return socket.addEventListener({
			entities: [Dto.Event.Entity.Environment],
			callback: debounce,
		})
	}, [socket])

	const stopWatchingEnvironments = useCallback<Environments['stopWatchingEnvironments']>((watchId) => {
		const debounce = socket.removeEventListener(watchId)
		if (debounce) {
			debounce.clear()
		}
	}, [socket])

	const watchDetail = useCallback<Environments['watchDetail']>((detailId, onDetail) => {
		const debounce = new Debounce(
			() => {
				fetchDetail(detailId)
					.then(detail => onDetail(detail))
					.catch(() => onDetail(null))
			},
			REQUESTS_DEBOUNCE_INTERVAL,
		)
		
		return socket.addEventListener({
			entities: [Dto.Event.Entity.Environment, Dto.Event.Entity.Instance],
			match: (message) => message.meta?.environmentId === detailId,
			callback: debounce,
		})
	}, [socket])

	const stopWatchingDetail = useCallback<Environments['stopWatchingDetail']>((watchId) => {
		const debounce = socket.removeEventListener(watchId)
		if (debounce) {
			debounce.clear()
		}
	}, [socket])

	const mapInstances = useCallback<Environments['mapInstances']>((instances, services, namePhrase): MappedInstances[] => {
		return instances
			.map(instance => {
				const service = services.find(service => service.id === instance.serviceId)
				if (namePhrase && !service?.name.toLowerCase().includes(namePhrase.toLowerCase())) {
					return null
				}
				return {
					...instance,
					serviceName: service?.name || 'unknown',
					serviceTag: service?.tag || '',
				} as MappedInstances
			})
			.filter(filterOutEmptyFn)
			.sort(sortObjectFn('serviceName'))
	}, [services, fetchServices])

	const updateEnvironmentInstances = useCallback<Environments['updateEnvironmentInstances']>(async (data) => {
		return await getApiClient().instances.patch(data.id, data)
	}, [getApiClient])

	const restartEnvironmentInstance = useCallback<Environments['restartEnvironmentInstance']>((envId, instanceId) => {
		return getApiClient().instances.restart(envId, instanceId)
	}, [getApiClient])

	return (
		<context.Provider value={{
			activeItems,
			inactiveItems,
			fetchEnvironments,
			createEnvironment,
			updateEnvironment,
			fetchDetail,
			startEnvironment,
			stopEnvironment,
			restartEnvironment,
			deleteEnvironment,
			watchEnvironments,
			stopWatchingEnvironments,
			watchDetail,
			stopWatchingDetail,
			mapInstances,
			updateEnvironmentInstances,
			restartEnvironmentInstance,
		}}>
			{children}
		</context.Provider>
	)
}

export const useEnvironments = (): Environments => useContext(context)

export enum EnvironmentsErrorCodes {
	InvalidParameters = 'invalid_parameters',
	EnvironmentLimitExceeded = 'environment_limit_exceeded',
	EnvironmentValidationDomain = 'environment_validation_domain',
}

export enum EnvironmentsErrorMessages {
	InvalidParameters = 'Some of the form fields are invalid.',
	EnvironmentLimitExceeded = 'You can not activate another environment because the maximum limit has been reached',
	EnvironmentValidationDomain = 'Domain already exists',
	Unknown = 'An unknown error has ocurred. Please, try again later.',
}

export interface MappedInstances extends EnvironmentInstance {
	serviceName: string
	serviceTag: string
}
