import React, { createContext, useCallback, useContext } from 'react'
import { initContextFn as fn } from '../utils/context'
import { ApiEndpoints } from './api'
import { useSettings } from './settings'

const contextDefaults = {
	googleLoginRedirect: fn<() => void>(),
	githubLoginRedirect: fn<() => void>(),
}
const context = createContext(contextDefaults)
export type Navigation = typeof contextDefaults

export const navigationPaths = {
	lobby: '/',
	environments: '/environments',
	environmentCreate: '/environments/create',
	environmentDetail: (id: number): string => `/environments/${id}`,
	services: '/services',
	serviceDetail: (id: number): string => `/services/${id}`,
	users: '/users',
	settings: '/settings',
}

export const NavigationProvider: React.FC = ({ children }) => {
	const { apiUrl } = useSettings()

	const googleLoginRedirect = useCallback(() => {
		window.location.href = `${apiUrl}${ApiEndpoints.googleLogin}`
	}, [apiUrl])

	const githubLoginRedirect = useCallback(() => {
		window.location.href = `${apiUrl}${ApiEndpoints.githubLogin}`
	}, [apiUrl])

	return (
		<context.Provider value={{ googleLoginRedirect, githubLoginRedirect }}>
			{children}
		</context.Provider>
	)
}

export const useNavigation = (): Navigation => useContext(context)
