import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { Stack } from '../components/Stack'
import { AppTitle } from '../components/AppTitle'
import { navigationPaths } from '../context/navigation'
import { useHistory } from 'react-router-dom'
import { useUserPermissions } from '../context/userPermissions'
import { PatchUserRole, PermissionsResources } from '@smartsupp/dapi-client'
import { useUsers } from '../context/users'
import { InlineSelect } from '../components/InlineSelect'
import { useUser } from '../context/user'

export const UsersPage: React.FC = () => {
  const { hasPermissions } = useUserPermissions()
  const { fetchUsers } = useUsers()
  const history = useHistory()

  if (!hasPermissions(PermissionsResources.ViewUsers)) {
    history.replace(navigationPaths.lobby)
    return null
  }

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
      <>
        <AppTitle subtitle='Users'/>
        <Stack direction='vertical' gap={2}>

          <h2 className='Content__title'>Users</h2>

          <ListTable/>

        </Stack>
      </>
  )
}

const ListTable: React.FC = () => {
  const { hasPermissions } = useUserPermissions()
  const { users, updateRole } = useUsers()
  const { data } = useUser()

  const roleOptions = useMemo(() => {
    return Object.values(PatchUserRole).map(role => ({
      title: role,
      value: role,
    }))
  }, [])

  const handleRoleChange = useCallback((id: number, e: ChangeEvent<HTMLSelectElement>) => {
    if (data?.id === id) {
      e.target.value = data.role
      return alert('You can not change your own role')
    }

    updateRole(id, e.target.value as PatchUserRole)
  }, [updateRole, data])

  const isCurrentUser = useCallback((id: number) => data?.id === id, [data])

  return (
    <table className='Table'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <th>Created</th>
          <th>Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {
          users.map((user) => (
            <tr key={user.id}>
              <td>
                {isCurrentUser(user.id) ? (
                  <b>{user.name}</b>
                ) : (
                  <span>{user.name}</span>
                )}
              </td>
              <td>
                {!isCurrentUser(user.id) && hasPermissions(PermissionsResources.ManageUsers) ? (
                  <InlineSelect
                    options={roleOptions}
                    defaultValue={user.role}
                    onChange={e => handleRoleChange(user.id, e)}
                  />
                ) : (
                  <span>{user.role}</span>
                )}
              </td>
              <td>{new Date(user.createdAt).toLocaleString()}</td>
              <td>{new Date(user.updatedAt).toLocaleString()}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}
