import React from 'react'


export const EnvironmentInstancesGrid: React.FC = ({ children }) => {
	return (
		<div className='EnvironmentInstancesGrid'>
			{children}
		</div>
	)
}
