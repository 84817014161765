import cc from 'classcat'

export interface ButtonProps {
	text: string
	style?: 'default' | 'slim' | 'link' | 'success' | 'danger'
	full?: boolean
	submit?: boolean
	disabled?: boolean
	onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({text, style, full, submit, disabled, onClick}) => {
	return (
		<button
			className={cc([
				'Button',
				{ 'Button--slim': style === 'slim' },
				{ 'Button--link': style === 'link' },
				{ 'Button--success': style === 'success' },
				{ 'Button--danger': style === 'danger' },
				{ 'Button--full': full },

			])}
			onClick={() => onClick ? onClick() : {}}
			type={submit ? 'submit' : 'button' }
			disabled={disabled}
		>
			{text}
		</button>
	)
}
