import { PermissionsResources } from '@smartsupp/dapi-client'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppTitle } from '../components/AppTitle'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { EnvironmentForm } from '../components/EnvironmentForm'
import { Stack } from '../components/Stack'
import { navigationPaths } from '../context/navigation'
import { useUserPermissions } from '../context/userPermissions'

export const EnvironmentCreatePage: React.FC = () => {
  const history = useHistory()
  const { hasPermissions } = useUserPermissions()

  if (!hasPermissions(PermissionsResources.ManageEnvironments)) {
    history.replace(navigationPaths.environments)
    return null
  }

  return (
      <>
        <AppTitle subtitle="Create Environment"/>

        <Stack gap={2}>
          <Breadcrumbs items={[
              { title: 'Environments', navigateTo: navigationPaths.environments },
              { title: 'Create Environment' },
            ]}/>
          <EnvironmentForm
            onSaved={(env) => history.push(navigationPaths.environmentDetail(env.id))}
          />
        </Stack>
      </>
  )
}
