import React from 'react'
import { Content } from './Content'
import { NotificationBar } from './NotificationBar'
import { SidePanel } from './SidePanel'

const Layout: React.FC = ({ children }) => <div className="App AppLayout">{ children }</div>

const Common: React.FC = ({ children }) => (
	<Layout>
		<SidePanel />
        <NotificationBar />
        <Content>
			{ children }
		</Content>
	</Layout>
)

export const AppLayout = Object.assign(Layout, { Common })
