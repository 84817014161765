import { createContext, useCallback, useContext, useMemo } from 'react'
import { Dto } from '@smartsupp/dapi-client-dto'
import { useSettings } from './settings'
import { io, Socket as Client } from 'socket.io-client'
import { createUid } from '../utils/uid'
import { Debounce } from '../utils/debounce'
import { initContextFn as fn } from '../utils/context'

const contextDefaults = {
	addEventListener: fn<(listener: SocketListener) => string>(),
	removeEventListener: fn<(id: string) => Debounce | null>(),
	getSocket: fn<() => Client>(),
}
const context = createContext(contextDefaults)
export type Socket = typeof contextDefaults

interface SocketListeners {
	[id: string]: SocketListener
}
export interface SocketListener {
	callback: SocketListenerCallback | Debounce
	entities?: Dto.Event.Entity[]
	match?: SocketListenerMatch
}
type SocketListenerCallback = (message: Dto.Event.Message) => void
type SocketListenerMatch = (message: Dto.Event.Message) => boolean

export const SocketProvider: React.FC = ({ children }) => {
	const { apiUrl } = useSettings()
	const eventListeners = useMemo<SocketListeners>(() => ({}), [])

	/**
	 * Custom implementation because of **socket.off** does not work
	 */
	const handleSocketEvents = useCallback((message: Dto.Event.Message) => {
		for (const { callback, match, entities } of Object.values(eventListeners)) {
			if (typeof match !== 'undefined' && !match(message)) {
				continue
			}

			if (typeof entities !== 'undefined' && !entities.includes(message.entity)) {
				continue
			}

			if (callback instanceof Debounce) {
				callback.call()
			} else {
				callback(message)
			}
		}
	}, [eventListeners])

	const socket = useMemo(() => {
		const socket =  io(apiUrl, {
			withCredentials: true,
		})

		socket.on(Dto.Socket.Name.Event, handleSocketEvents)

		return socket
	}, [apiUrl, handleSocketEvents])

	/**
	 * Add event listener which will trigger **callback** for optionally specified **entities** and optional **match** function.
	 * @returns **watchId** which is required to remove event listener
	 */
	const addEventListener = useCallback<Socket['addEventListener']>((listener: SocketListener): string => {
		const id = createUid()
		eventListeners[id] = { ...listener }
		return id
	}, [socket])

	const removeEventListener = useCallback<Socket['removeEventListener']>((id: string): Debounce | null => {
		const listener = eventListeners[id]
		if (!listener) {
			return null
		}

		delete eventListeners[id]
		
		return listener.callback instanceof Debounce
			? listener.callback
			: null
	}, [socket])

	const getSocket = useCallback((): Client => {
		if (!socket) {
			throw new Error('Socket client is not initialized')
		}
		return socket
	}, [socket])

	return (
		<context.Provider value={{ addEventListener, removeEventListener, getSocket }}>
			{children}
		</context.Provider>
	)
}

export const useSocket = (): Socket => useContext(context)
