import React from 'react'
import cc from 'classcat'

export interface Props {
	placeholder: string
	onPhraseChanged: (phrase: string) => void
	autoFocus?: React.InputHTMLAttributes<HTMLInputElement>['autoFocus']
}

export const SearchFiled: React.FC<Props> = ({ placeholder, onPhraseChanged, autoFocus }) => {
	return (
		<input
			className={cc([
				'SearchField',
			])}
			placeholder={placeholder}
			onChange={(e => onPhraseChanged(e.target.value))}
			autoComplete='off'
			autoFocus={autoFocus}
      />
	)
}
