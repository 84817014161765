import React from 'react'

interface Props {
	reason: string
	className?: string
}

export const Loader: React.FC<Props> = ({ reason }) => {
	return (
		<_Loader className='Loader' reason={reason}/>
	)
}

export const LoaderInline: React.FC<Props> = ({ reason }) => {
	return (
		<_Loader className='LoaderInline' reason={reason}/>
	)
}

const _Loader: React.FC<Props & { className: string }> = ({ reason, className }) => {
	return (
		<div className={className}>
			<div className={`${className}__wrapper`}>
				<p className={`${className}__text`}>Loading...</p>
				{reason && (
					<p className={`${className}__reason`}>{reason}</p>
				)}
			</div>
		</div>
	)
}
