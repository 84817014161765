import React, { useCallback, useState } from 'react'
import { Stack } from './Stack'
import { useForm } from 'react-hook-form'
import { ServiceApi, ServiceDetail } from '@smartsupp/dapi-client'
import { FormInput } from './FormInput'
import { Button } from './Button'
import { getValidationError, ValidationError } from '../utils/request'
import { ServiceErrorMessages, useServices } from '../context/services'

interface Props {
	defaultValues?: ServiceDetail
	onSaved: (service: ServiceDetail) => void
}

export const ServiceForm: React.FC<Props> = ({ defaultValues, onSaved }) => {
	const { createService, updateService } = useServices()
	const { handleSubmit, register, reset, formState, setError } = useForm<ServiceApi.CreateBody>({
		defaultValues,
		reValidateMode: 'onChange',
	})
	const [submitDisabled, setSubmitDisabled] = useState(false)

	const handleValidationError = useCallback((error: ValidationError) => {
		switch (error.code) {
			default:
				alert(ServiceErrorMessages.Unknown)
		}
	}, [setError])

	/**
	 * Create new service and handle form effects
	 */
	const handleCreate = useCallback(async (values: ServiceApi.CreateBody) => {
		try {
			const service = await createService(values)
			onSaved(service)
		} catch (err) {
			const validationError = getValidationError(err)
			if (validationError) {
				handleValidationError(validationError)
			}
			setSubmitDisabled(false)
		}
	}, [createService, setSubmitDisabled, handleValidationError])

	/**
	 * Update service and handle form effects
	 */
	const handleUpdate = useCallback(async (values: ServiceApi.PatchBody) => {
		try {
			const service = await updateService(values)
			reset(values)
			onSaved(service)
		} catch (err) {
			const validationError = getValidationError(err)
			if (validationError) {
				handleValidationError(validationError)
			}
			alert((err as Error).message)
		} finally {
			setSubmitDisabled(false)
		}
	}, [updateService, reset, setSubmitDisabled, handleValidationError])

	/**
	 * Handle form submit
	 */
	const onSubmit = useCallback((values: ServiceApi.CreateBody) => {
		setSubmitDisabled(true)

		if (defaultValues?.id) {
			handleUpdate({
				...defaultValues,
				...values,
			})
		} else {
			handleCreate(values)
		}
	}, [handleCreate, handleUpdate, setSubmitDisabled])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack direction='vertical' gap={2}>
				<Button
					text='Save'
					submit
					disabled={submitDisabled}
				/>

				<Stack direction='vertical' gap={0.5}>
					<FormInput
						register={register(
							'name',
							{ required: 'Fill in the service name.' }
						)}
						disabled={!!defaultValues?.id}
						errors={formState.errors}
						label='Name of service'
						type='text'
					/>
					<FormInput
						register={register('image', { required: 'Fill in the image name.' })}
						disabled={!!defaultValues?.id}
						errors={formState.errors}
						label='Image'
						type='text'
					/>
					<FormInput
						register={register('tag', { required: 'Fill in the default tag.' })}
						errors={formState.errors}
						label='Default tag'
						type='text'
					/>
					<FormInput
						register={register('mustache', { required: 'Fill in the mustache placeholder.' })}
						errors={formState.errors}
						label='Mustache placeholder'
						type='text'
					/>
				</Stack>
			</Stack>
		</form>
	)
}
