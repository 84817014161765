import React from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  items: Array<{
    title: string
    navigateTo?: string
  }>
}

export const Breadcrumbs: React.FC<Props> = ({ items }) => {
  if (items.length === 0) {
    return null
  }
  return (
    <div className='Breadcrumbs'>
      {items.flatMap((item, index) => {
        const elements = [
          item.navigateTo
            ? <NavLink key={`item${index}`} className='Breadcrumbs__link' to={item.navigateTo}>{item.title}</NavLink>
            : <span key={`item${index}`} className='Breadcrumbs__item'>{item.title}</span>          
        ]
        if (index < items.length - 1) {
          elements.push(
            <span key={`delim${index}`} className='Breadcrumbs__delimiter'>/</span>
          )
        }
        return elements
      })}
    </div>
  )
}
