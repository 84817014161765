import React, { useEffect } from 'react'
import { Stack } from '../components/Stack'
import { AppTitle } from '../components/AppTitle'
import { navigationPaths } from '../context/navigation'
import { useHistory } from 'react-router-dom'
import { useUserPermissions } from '../context/userPermissions'
import { AppSettings as SpecAppSettings, AppSettingsGitHubStatus, PermissionsResources } from '@smartsupp/dapi-client'

import GitHubPng from '../images/github.png'
import { Button } from '../components/Button'
import { useUser } from '../context/user'
import { useAppSettings } from '../context/appSettings'

export const AppSettingsPage: React.FC = () => {
  const { hasPermissions } = useUserPermissions()
  const { fetchSettings, appSettings } = useAppSettings()
  const history = useHistory()

  if (!hasPermissions(PermissionsResources.ManageSettings)) {
    history.replace(navigationPaths.lobby)
    return null
  }

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  if (!appSettings) return <>Fetching settings...</>

  return (
      <>
        <AppTitle subtitle='Settings'/>
        <Stack direction='vertical' gap={2}>

          <h2 className='Content__title'>Settings</h2>

          <GitHubSettings appSettings={appSettings} />

        </Stack>
      </>
  )
}

export const GitHubSettings: React.FC<{
  appSettings: SpecAppSettings,
}> = ({ appSettings }) => {
  const { githubLoginRedirect } = useUser()

  return (
      <div>
        <span><img src={GitHubPng} alt="github logo"/> GitHub Access Token</span>

        <table className='Table'>
          <thead>
            <tr>
              <th>Status</th>
              <th>Owner</th>
              <th>Expiration</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {appSettings.gitHub.status === AppSettingsGitHubStatus.Valid ? (
                  <>✅ Valid</>
                ) : (
                  <>❌ Invalid</>
                )}
              </td>
              <td>
                {appSettings.gitHub.owner ? (
                  <>{appSettings.gitHub.owner}</>
                ) : (
                  <>---</>
                )}
              </td>
              <td>
                {appSettings.gitHub.expiresAt ? (
                  <>{new Date(appSettings.gitHub.expiresAt).toLocaleString()}</>
                ) : (
                  <>---</>
                )}
              </td>
              <td>
                <Button text='Renew' style='slim' onClick={githubLoginRedirect}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  )
}
