import { useMemo } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { useUser } from './context/user'
import { EnvironmentsPage } from './pages/Environments'
import { EnvironmentCreatePage } from './pages/EnvironmentCreate'
import { EnvironmentDetailPage } from './pages/EnvironmentDetail'
import queryString  from 'query-string'
import { ErrorPage } from './pages/ErrorPage'
import { LoginPage } from './pages/LoginPage'
import { ServicesPage } from './pages/Services'
import { ServicesDetailPage } from './pages/ServicesDetail'
import { LobbyPage } from './pages/LobbyPage'
import { AppLayout } from './components/AppLayout'
import { useUserPermissions } from './context/userPermissions'
import { UsersPage } from './pages/Users'
import { AppSettingsPage } from './pages/AppSettings'

export default function Router(): JSX.Element {
  const { initialized: userInitialized, logged } = useUser()
  const { initialized: permissionsInitialized } = useUserPermissions()
  const queryParams = useMemo(() => {
    return queryString.parse(window.location.search)
  }, [queryString])

  if (typeof queryParams?.error === 'string') {
    return <ErrorPage errorCode={queryParams.error} queryParams={queryParams} />
  }

  if (!userInitialized || !permissionsInitialized || !logged) {
    return <LoginPage/>
  }

  return (
    <BrowserRouter>
      <AppLayout.Common>
          <Switch>
            <Route path='/' exact component={LobbyPage} />
            <Route path='/environments' exact component={EnvironmentsPage} />
            <Route path='/environments/create' exact component={EnvironmentCreatePage} />
            <Route path='/environments/:id' exact component={EnvironmentDetailPage} />
            <Route path='/services' exact component={ServicesPage} />
            <Route path='/services/:id' exact component={ServicesDetailPage} />
            <Route path='/users' exact component={UsersPage} />
            <Route path='/settings' exact component={AppSettingsPage} />
            <Redirect to='/environments' />
          </Switch>
      </AppLayout.Common>
      </BrowserRouter>
  )
}
