import React, { useCallback } from 'react'
import { useUser } from '../context/user'
import UserPng from '../images/user.png'

export const UserAvatar: React.FC = () => {
	const { data } = useUser()

	const swapFallbackAvatar = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
		e.currentTarget.src = UserPng
	}, [])

	return (
		<img 
			src={data?.thumbnail}
			className="SidePanel__user-thumbnail"
			onError={(e) => swapFallbackAvatar(e)}
		/>
	)
}
