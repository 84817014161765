import React from 'react'
import { useApi } from '../context/api'
import { useUser } from '../context/user'

export const NotificationBar: React.FC = () => {
  const { status } = useApi()
  const { loginExpired, logout } = useUser()
 
  const messages = []
  if (!status) {
    messages.push('⚠ No connection to API')
  }

  if (loginExpired) {
    messages.push(<>⚠ Your login status has expired. Go to <a className='Link' href="#relog" onClick={logout}>the login page</a>.</>)
  }

  if (messages.length === 0) {
    return null
  }

  return (
    <div className='NotificationBar'>
      <span className='NotificationBar__message'>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </span>
    </div>
  )
}
