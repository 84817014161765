import React, { createContext, useCallback, useContext, useState } from 'react'
import { useApi } from './api'
import { ServiceSimple, ServiceApi, ServiceDetail } from '@smartsupp/dapi-client'
import { initContextFn as fn } from '../utils/context'

const contextDefaults = {
	services: [] as ServiceSimple[],
	fetchServices: fn<() => Promise<void>>(),
	createService: fn<(service: ServiceApi.CreateBody) => Promise<ServiceDetail>>(),
	updateService: fn<(service: ServiceApi.PatchBody) => Promise<ServiceDetail>>(),
	fetchServiceDetail: fn<(id: number) => Promise<ServiceDetail>>(),
}
const context = createContext(contextDefaults)
export type Services = typeof contextDefaults

export const  ServicesProvider: React.FC = ({ children }) => {
	const [services, setServices] = useState<ServiceSimple[]>([])
	const { getApiClient } = useApi()

	const fetchServices = useCallback<Services['fetchServices']>(async (): Promise<void> => {
		const services = await getApiClient().service.list()
		setServices(services)
	}, [getApiClient, setServices])

	const createService = useCallback<Services['createService']>(async (service) => {
		const newService = await getApiClient().service.create(service)
		await fetchServices()
		return newService
	}, [getApiClient, fetchServices])

	const updateService = useCallback<Services['updateService']>(async (service) => {
		const updatedService = await getApiClient().service.patch(service)
		await fetchServices()
		return updatedService
	}, [getApiClient, fetchServices])

	const fetchServiceDetail = useCallback<Services['fetchServiceDetail']>(async (id) => {
		return await getApiClient().service.get(id, {
			validateStatus: (status: number) => [200, 404].includes(status)
		})
	}, [getApiClient])

	return (
		<context.Provider value={{ services, fetchServices, createService, updateService, fetchServiceDetail }}>
			{ children }
		</context.Provider>
	)
}

export const useServices = (): Services => useContext(context)

export enum ServiceErrorCodes {
}

export enum ServiceErrorMessages {
	Unknown = 'An unknown error has ocurred. Please, try again later.',
}
