import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { AppTitle } from '../components/AppTitle'
import { Badge, BadgeStyle } from '../components/Badge'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { Button } from '../components/Button'
import { EnvironmentForm } from '../components/EnvironmentForm'
import { Stack } from '../components/Stack'
import { EnvironmentsErrorCodes, EnvironmentsErrorMessages, MappedInstances, useEnvironments } from '../context/environments'
import { navigationPaths } from '../context/navigation'
import { getValidationError } from '../utils/request'
import { EnvironmentDetail, EnvironmentDetailState, EnvironmentInstanceState, PermissionsResources, ResponseOk, ServiceSimple } from '@smartsupp/dapi-client'
import { useServices } from '../context/services'
import { SearchFiled } from '../components/SearchFiled'
import { ElapsedTime } from '../components/ElapsedTime'
import { useUserPermissions } from '../context/userPermissions'
import { LoaderInline } from '../components/Loader'
import { ExternalLink } from '../components/ExternalLink'

export const EnvironmentDetailPage: React.FC = () => {
  const { params } = useRouteMatch<{ id: string }>()
  const { fetchDetail, watchDetail, stopWatchingDetail } = useEnvironments()
  const { fetchServices, services } = useServices()
  const [initialized, setInitialized] = useState(false)
  const [detail, setDetail] = useState<EnvironmentDetail | null>()
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const { hasPermissions } = useUserPermissions()
  const id = Number(params.id)

  if (!hasPermissions(PermissionsResources.ViewEnvironments)) {
    history.replace(navigationPaths.lobby)
    return null
  }

  /**
   * Handle initialization
   */
  useEffect(() => {
    if (Number.isNaN(id)) {
      setInitialized(true)
      return
    }
    if (services.length === 0) {
      fetchServices()
    }
    if (typeof detail !== 'undefined' && services.length > 0) {
      setInitialized(true)
    }
  }, [id, detail, services, fetchServices, setDetail, setInitialized])

  /**
   * Handle detail changes.
   * Called when detail has been fetched fort the first time, re-fetched with polling or updated by the user.
   */
  const detailChanged = useCallback((newDetail: EnvironmentDetail | null) => {
    if (!newDetail) {
      // TODO better detection of 401 / 403
      history.push(navigationPaths.environments)
      return
    }
    setDetail(newDetail)
  }, [setDetail])

  /**
   * Handle detail update
   */
  const detailUpdated = useCallback((newDetail: EnvironmentDetail) => {
    detailChanged(newDetail)
    setEditing(false)
  }, [detailChanged, setEditing])

  /**
   * Initial detail fetch
   */
  useEffect(() => {
    fetchDetail(id)
      .then(detail => {
        detailChanged(detail)
      })
      .catch(() => {
        detailChanged(null)
      })
  }, [fetchDetail, detailChanged, id])

  /**
   * Watching for changes
   */
  useEffect(() => {
    const watchId = watchDetail(id, (detail) => {
      if (!editing) {
        detailChanged(detail)
      } else {
        // TODO if detail has change, notify user
      }
    })
    return () => {
      stopWatchingDetail(watchId)
    }
  }, [watchDetail, stopWatchingDetail, detailChanged, editing, id])

  if (!initialized) {
    return <LoaderInline reason='Loading environment detail..'/>
  }

  if (!detail) {
    return <Redirect to={navigationPaths.environments} />
  }

  return (
      <>
        <AppTitle subtitle={`Environment "${detail.name}"`}/>

        <Stack direction='vertical' gap={2}>
          <Breadcrumbs items={[
            { title: 'Environments', navigateTo: navigationPaths.environments },
            { title: detail.name },
          ]}/>

          <DetailActions
            setEditing={setEditing}
            editing={editing}
            detail={detail}
            detailChanged={detailChanged}
          />

          {editing ? (
            <EnvironmentForm
              defaultValues={detail}
              onSaved={(detail) => detailUpdated(detail)}
            />
          ) : (
            <>
              <DetailInfo detail={detail}/>
              <InstancesList detail={detail} services={services}/>
            </>
          )}
        </Stack>
      </>
  )
}

const DetailActions: React.FC<{
  detail: EnvironmentDetail
  editing: boolean
  setEditing: (value: React.SetStateAction<boolean>) => void
  detailChanged: (detail: EnvironmentDetail | null) => void
}> = ({ detail, editing, setEditing, detailChanged }) => {
  const { startEnvironment, stopEnvironment, restartEnvironment, deleteEnvironment, fetchDetail } = useEnvironments()
  const history = useHistory()
  const { hasPermissions } = useUserPermissions()
  const canManageEnvironments = useMemo(() => hasPermissions(PermissionsResources.ManageEnvironments), [hasPermissions])
  const canManageInstances = useMemo(() => hasPermissions(PermissionsResources.ManageEnvironmentInstances), [hasPermissions])

  /**
   * Shows confirm dialog with **confirmText**. Calls **onConfirmed** when dialog is confirmed and finally fetch and updates new detail when confirmed.
   */
  const confirmAndRefetch = useCallback(
    async (
      confirmText: string,
      onConfirmed: (id: number) => Promise<ResponseOk>
    ) => {
      const confirmed = confirm(confirmText)
      if (confirmed) {
        try {
          const ok = await onConfirmed(detail.id)
          if (ok.ok) {
            const newDetail = await fetchDetail(detail.id)
            detailChanged(newDetail)
          }
        } catch (error) {
          const validationError = getValidationError(error)
          if (validationError && validationError.code === EnvironmentsErrorCodes.EnvironmentLimitExceeded) {
            alert(EnvironmentsErrorMessages.EnvironmentLimitExceeded)
          } else {
            alert((error as Error).message)
          }
        }
      }
    },
    [],
  )

  const handleEditing = useCallback((edit: boolean) => {
    if (hasPermissions(PermissionsResources.ManageEnvironmentInstances)) {
      setEditing(edit)
    }
  }, [setEditing])

  const handleStart = useCallback(async () => {
    confirmAndRefetch(
      'Do you really want to start the environment?',
      (id) => startEnvironment(id)
    )
  }, [])

  const handleStop = useCallback(async () => {
    confirmAndRefetch(
      'Do you really want to stop the environment?',
      (id) => stopEnvironment(id)
    )
  }, [])

  const handleRestart = useCallback(async () => {
    confirmAndRefetch(
      'Do you really want to restart the environment?',
      (id) => restartEnvironment(id)
    )
  }, [])

  const handleDelete = useCallback(async () => {
    const confirmed = confirm('Do you really want to delete the environment?')
    if (confirmed) {
      await deleteEnvironment(detail.id)
      history.push(navigationPaths.environments)
    }
  }, [])

  return (
    <Stack direction='horizontal'>
      {editing ? (
        <Button
          text='Discard changes'
          onClick={() => handleEditing(false)}
        />
      ) : (
        <Button
          text='Edit'
          style='success'
          onClick={() => handleEditing(true)}
        />
      )}

      {canManageEnvironments && (
        <>
          {([EnvironmentDetailState.Up, EnvironmentDetailState.Partial] as EnvironmentDetailState[]).includes(detail.state) && (
            <Button
              text='Stop'
              onClick={() => handleStop()}
              disabled={!canManageInstances}
            />
          )}
          {([EnvironmentDetailState.Down, EnvironmentDetailState.Partial] as EnvironmentDetailState[]).includes(detail.state) && (
            <Button
              text='Start'
              onClick={() => handleStart()}
              disabled={!canManageInstances}
            />
          )}
          <Button
            text='Restart'
            onClick={() => handleRestart()}
            disabled={!canManageInstances}
          />
          <Button
            text='Delete'
            style='danger'
            onClick={() => handleDelete()}
            disabled={!canManageInstances}
          />
        </>
      )}
    </Stack>
  )
}

const DetailInfo: React.FC<{ detail: EnvironmentDetail }> = ({ detail }) => {
  return (
    <Stack>
      <span>Info</span>

      <table className='Table'>
        <tbody>
            <tr>
              <th>Name</th>
              <td>{detail.name}</td>
            </tr>
            <tr>
              <th>Domain</th>
              <td>{detail.domain}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{detail.description}</td>
            </tr>
            <tr>
              <th>Urls</th>
              <td>
                {detail.url ? (<Stack direction='vertical' gap={0}>
                  <ExternalLink href={detail.url} target='_blank'>{detail.url}</ExternalLink>
                  <ExternalLink href={`https://app.${detail.domain}/app/dashboard`} target='_blank'>Dashboard</ExternalLink>
                  <ExternalLink href={`https://app.${detail.domain}/admin`} target='_blank'>Root</ExternalLink>
                  <ExternalLink href={`https://openid.${detail.domain}/admin`} target='_blank'>Keycloak</ExternalLink>
                </Stack>
                ) : (
                  <></>
                )}
              </td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{detail.type}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>
                <Badge
                  text={detail.state}
                  style={detail.state === EnvironmentDetailState.Up ? BadgeStyle.success : BadgeStyle.danger}
                />
              </td>
            </tr>
            <tr>
              <th>Author</th>
              <td>{detail.creator.name}</td>
            </tr>
            <tr>
              <th>Created</th>
              <td>{new Date(detail.createdAt).toLocaleString()}</td>
            </tr>
            <tr>
              <th>Updated</th>
              <td>{new Date(detail.updatedAt).toLocaleString()}</td>
            </tr>
          </tbody>
      </table>
    </Stack>
  )
}

const InstancesList: React.FC<{
  detail: EnvironmentDetail
  services: ServiceSimple[]
}> = ({ detail, services }) => {
  const { mapInstances, restartEnvironmentInstance } = useEnvironments()
  const [namePhrase, setNamePhrase] = useState<string>()

  const handleRestart = useCallback(async (instance: MappedInstances) => {
    const confirmed = confirm( `Do you really want to restart the "${instance.serviceName}"?`)
    if (confirmed) {
      try {
        restartEnvironmentInstance(detail.id, instance.id)
      } catch (error) {
        alert('Failed to restart the instance')
        console.error(error)
      }
    }
  }, [detail])

  const mapped = useMemo(() => {
    return mapInstances(detail.instances, services, namePhrase)
  }, [mapInstances, detail, services, namePhrase])

  return (
    <Stack>
      <span>Instances</span>

      <SearchFiled
        placeholder='Search by name'
        onPhraseChanged={(phrase => setNamePhrase(phrase))}
        autoFocus={true}
      />

      <table className='Table'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Tag</th>
          <th>Status</th>
          <th>Uptime</th>
          <th>Updated by</th>
          <th>Updated at</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {
        mapped.map(instance => (
          <tr
            key={instance.id}
          >
            <td>{instance.serviceName}</td>
            <td>
              {instance.tag !== instance.serviceTag ? (
                <b>{instance.tag}</b>
              ) : (
                <span>{instance.tag}</span>
              )}
            </td>
            <td>
              <Badge
                text={instance.state}
                style={([EnvironmentInstanceState.Up, EnvironmentInstanceState.Starting] as EnvironmentInstanceState[]).includes(instance.state)
                  ? BadgeStyle.success
                  : BadgeStyle.danger
                }
              />
            </td>
            <td>
              <ElapsedTime from={instance.startedAt}/>
            </td>
            <td>{instance.updater.name}</td>
            <td className='Table__cell--nowrap'>{new Date(instance.updatedAt).toLocaleString()}</td>
            <td>
              <Button
                text='Restart'
                style='slim'
                onClick={() => handleRestart(instance)}
              />
            </td>
          </tr>
        ))
        }
        </tbody>
      </table>
    </Stack>
  )
}
