import React, { ChangeEvent } from 'react'
import cc from 'classcat'

type Props = { 
	options: { value: string, title: string }[]
	label?: string
	disabled?: boolean
	defaultValue?: string
	onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

export const InlineSelect: React.FC<Props> = ({ label, options, disabled, defaultValue, onChange }) => {
	return (
		<>
			{label && (
				<label className='FormLabel'>{label}</label>
			)}
			<select
				className={cc([
					'InlineSelect',
				])}
				disabled={disabled}
				onChange={(e) => onChange && onChange(e)}
				defaultValue={defaultValue}
			>
				{
					options.map(option => (
						<option key={option.value} value={option.value}>{option.title}</option>
					))
				}
			</select>
		</>
	)
}
