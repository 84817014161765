import React, { useCallback, useMemo, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { Stack } from './Stack'
import { useDockerImages } from '../context/dockerImages'
import cc from 'classcat'
import { sortObjectFn } from '../utils/sort'
import { FormInstance } from './EnvironmentForm'

export interface FormSelectProps {
	register: UseFormRegisterReturn
	instance: FormInstance
}

export const EnvironmentInstanceSelect: React.FC<FormSelectProps> = (props) => {
	return (	
		<Stack direction='horizontal' align='center'>
			<Combobox {...props} />
		</Stack>
	)
}

const Combobox: React.FC<FormSelectProps> = ({ register, instance }) => {
	const defaultOptions = useMemo(() => {
		const options = [{ value: instance.tag, title: instance.tag }]
		if (instance.tag !== instance.defaultTag) {
			options.push({ value: instance.defaultTag, title: instance.defaultTag })
		}
		return options
	}, [instance])
	const [options, setOptions] = useState(defaultOptions)
	const [isFetching, setIsFetching] = useState(false)
	const { fetchImageTags } = useDockerImages()

	const fetchDockerImageTags = useCallback(async () => {
		if (isFetching) {
			return
		}
		setIsFetching(true)

		try {
			const tags = await fetchImageTags(instance.image)
			setOptions([
				...defaultOptions,
				...tags
					.filter(tag => !defaultOptions.some(option => option.title === tag.name))
					.sort(sortObjectFn('name'))
					.map(tag => ({
						title: tag.name,
						value: tag.name,
					}))
			])
		} catch {
			console.error('Failed to fetch docker image tags')
		} finally {
			setIsFetching(false)
		}
	}, [isFetching, defaultOptions, setIsFetching, setOptions, fetchImageTags])

	return (
		<>
			<label className='FormLabel EnvironmentInstanceSelect__label'>{instance.serviceName}</label>
			<select 
				{...register}
				className={cc([
					'EnvironmentInstanceSelect',
					{
						'EnvironmentInstanceSelect--fetching': isFetching,
					},
				])}
				onFocus={() => fetchDockerImageTags()}
			>
				{
					isFetching && (
						<option className='EnvironmentInstanceSelect__loader' value="">loading...</option>
					)
				}
				{
					!isFetching &&
					options.map((option, index) => (
						<option
							className='EnvironmentInstanceSelect__option'
							key={`option-${index}`}
							value={option.value}
						>
							{option.title}
						</option>
					))
				}
			</select>
		</>
	)
}
