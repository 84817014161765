import Axios from 'axios'
import { AxiosAdapter } from '@openapi-client/adapter-axios'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSettings } from './settings'
import Cookies from 'js-cookie'
import { SESSION_ID_COOKIE_NAME } from '../constants'
import { SmartsuppDapiClient, Status } from '@smartsupp/dapi-client'
import { useSocket } from './socket'
import { initContextFn as fn, VoidFn } from '../utils/context'
import qs from 'query-string'

const RefreshStatusInterval = 10000

const defaultContext = {
	status: null as Status | null,
	getSessionId: fn<() => string | false>(),
	clearSessionId: fn<VoidFn>(),
	getApiClient: fn<() => SmartsuppDapiClient>(),
}
const context = createContext(defaultContext)
export type Api = typeof defaultContext

export const ApiEndpoints = {
	googleLogin: '/user/login/google',
	githubLogin: '/user/login/github',
	logout: '/user/logout',
}

export const ApiProvider: React.FC = ({ children }) => {
	const { apiUrl } = useSettings()
	const { getSocket } = useSocket()
	const [status, setStatus] = useState<Status | null>(null)

	const apiClient = useMemo(() => {
		const axios = Axios.create({
			baseURL: apiUrl,
			withCredentials: true,
		})
		const adapter = new AxiosAdapter(axios, {})
		
		return new SmartsuppDapiClient(adapter)
	}, [apiUrl, getSocket])

	const refreshStatus = useCallback(async () => {
		try {
			const status = await apiClient.status.get()
			setStatus(status)
		} catch (err) {
			setStatus(null)
		}
	}, [apiClient, setStatus])

	const getSessionId = useCallback<Api['getSessionId']>((): string | false => {
		const query = qs.parse(window.location.search)
		if (query.token) {
			Cookies.set(SESSION_ID_COOKIE_NAME, query.token)
		}
		return Cookies.get(SESSION_ID_COOKIE_NAME) || false
	}, [])

	const clearSessionId = useCallback<Api['clearSessionId']>((): void => {
		Cookies.remove(SESSION_ID_COOKIE_NAME)
	}, [])

	const getApiClient = useCallback<Api['getApiClient']>((): SmartsuppDapiClient => {
		if (!apiClient) {
			throw new Error('Api client is not initialized')
		}
		return apiClient
	}, [apiClient])

	// ping status
	useEffect(() => {
		const interval = setInterval(() => {
			refreshStatus()
		}, RefreshStatusInterval)
		refreshStatus()

		return () => clearInterval(interval)
	}, [apiUrl])

	return (
		<context.Provider value={{ status, getSessionId, clearSessionId, getApiClient }}>
			{children}
		</context.Provider>
	)
}

export const useApi = (): Api => useContext(context)
