import React, { createContext, useContext } from 'react'

const contextDefaults = {
	apiUrl: 'null',
}
const context = createContext(contextDefaults)
export type Settings = typeof contextDefaults

export const  SettingsProvider: React.FC<{ settings: Record<string, unknown> }> = ({ children, settings }) => {
	
	function validate(): Settings {
		if (
			!settings ||
			typeof settings.apiUrl !== 'string'
		) {
			throw TypeError('Invalid settings object')
		}
		return {
			apiUrl: settings.apiUrl,
		}
	}

	return (
		<context.Provider value={validate()}>
			{ children }
		</context.Provider>
	)
}

export const useSettings = (): Settings => useContext(context)
