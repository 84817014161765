export function formatElapsedTime(from: Date, to = new Date): string {
	const interval = getInterval(from, to)

	const result = Math.floor(interval.days) === 0
		? ''
		: `${pad(interval.days, false, 0)}d `
	return `${result}${pad(interval.hours, true)}:${pad(interval.minutes, true)}:${pad(interval.seconds, true)}`
}

function getInterval(from: Date, to: Date) {
	const milliseconds = to.getTime() - from.getTime()
	return {
		days: Math.trunc(milliseconds / 86400000),
		hours: Math.trunc(milliseconds / 3600000) % 24,
		minutes: Math.trunc(milliseconds / 60000) % 60,
		seconds: Math.trunc(milliseconds / 1000) % 60,
		milliseconds: Math.trunc(milliseconds) % 1000,
		microseconds: Math.trunc(milliseconds * 1000) % 1000,
		nanoseconds: Math.trunc(milliseconds * 1e6) % 1000
	}
}

function pad(value: number, absolute?: boolean, padEnd = 2): string {
	const floored = Math.floor(value)
	const calculated = absolute
		? Math.abs(floored)
		: floored
	return calculated.toString().padStart(padEnd, '0')
}

export function getDate(date: string | Date): Date {
	switch (typeof date) {
		case 'object': return date
		case 'string': return new Date(date)
		default: throw new Error('Unsupported date format')
	}
}
