import { PermissionsResources } from '@smartsupp/dapi-client'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppTitle } from '../components/AppTitle'
import { Stack } from '../components/Stack'
import { navigationPaths } from '../context/navigation'
import { useUserPermissions } from '../context/userPermissions'

export const LobbyPage: React.FC = () => {
  const { hasPermissions } = useUserPermissions()
  const history = useHistory()

  if (hasPermissions(PermissionsResources.ViewEnvironments)) {
    history.replace(navigationPaths.environments)
    return null
  }

  return (
    <>
        <AppTitle subtitle='Lobby'/>
        <Stack direction='vertical' gap={2}>
          <h2 className='Content__title'>Lobby</h2>

          <p>
            Hi there 👋<br/>
            You are sucessfully logged in 🎉 BUT you have to be approved by one of DDT admins.
          </p>

          <p>
            There is currently no official list of admins so you will have to go and ask someone.
          </p>
        </Stack>
      </>
  )
}

