import React, { useEffect } from 'react'
import { Stack } from '../components/Stack'
import { Button } from '../components/Button'
import { AppTitle } from '../components/AppTitle'
import { useServices } from '../context/services'
import { navigationPaths } from '../context/navigation'
import { useHistory } from 'react-router-dom'
import { useUserPermissions } from '../context/userPermissions'
import { PermissionsResources } from '@smartsupp/dapi-client'

export const ServicesPage: React.FC = () => {
  const { hasPermissions } = useUserPermissions()
  const { fetchServices } = useServices()
  const history = useHistory()

  if (!hasPermissions(PermissionsResources.ViewServices)) {
    history.replace(navigationPaths.lobby)
    return null
  }

  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  return (
      <>
        <AppTitle subtitle='Services'/>
        <Stack direction='vertical' gap={2}>

          <h2 className='Content__title'>Services</h2>

          <Button text='Create Service' disabled />

          <ListTable/>

        </Stack>
      </>
  )
}

const ListTable: React.FC = () => {
  const { services } = useServices()
  const history = useHistory()

  return (
    <table className='Table'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Image</th>
          <th>Default tag</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {
          services.map((item) => (
            <tr 
              key={item.id}
              className='Table__row--clickable'
              onClick={() => history.push(navigationPaths.serviceDetail(item.id))}
            >
              <td>{item.name}</td>
              <td>{item.image}</td>
              <td>{item.tag}</td>
              <td className='Table__cell--right'>
                <Button text='Detail' style='slim'/>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}
