import cc from 'classcat'

export interface Props {
	text: string
	style: BadgeStyle
}
export enum BadgeStyle {
	default = 'default',
	success = 'success',
	danger = 'danger',
}

export const Badge: React.FC<Props> = ({text, style}) => {
	return (
		<span
			className={cc([
				'Badge',
				{ 'Badge--default': style === BadgeStyle.default },
				{ 'Badge--success': style === BadgeStyle.success },
				{ 'Badge--danger': style === BadgeStyle.danger },
			])}
		>
			{text}
		</span>
	)
}
