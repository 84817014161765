import React from 'react'
import { ApiProvider } from './api'
import { AppSettingsProvider } from './appSettings'
import { DockerImagesProvider } from './dockerImages'
import { EnvironmentsProvider } from './environments'
import { NavigationProvider } from './navigation'
import { ServicesProvider } from './services'
import { SocketProvider } from './socket'
import { UserProvider } from './user'
import { UserPermissionsProvider } from './userPermissions'
import { UsersProvider } from './users'

export const AppProviders: React.FC = ({ children }) => {
  return (
    <>
      {
        combine(children, [
          SocketProvider,
          NavigationProvider,
          ApiProvider,
          AppSettingsProvider,
          UserProvider,
          UserPermissionsProvider,
          EnvironmentsProvider,
          ServicesProvider,
          DockerImagesProvider,
          UsersProvider,
        ])
      }
    </>
  )
}

function combine(children: React.ReactNode, providers: React.FC[]): React.ReactNode {
  let Combined: React.ReactNode = children

  for (const Provider of providers.reverse()) {
    Combined = <Provider children={Combined} />
  }

  return Combined
}
