import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { SettingsProvider } from './context/settings'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'

fetch('/settings.json')
  .then(async (data) => {
    const settings = await data.json()
    ReactDOM.render(
      <React.StrictMode>
        <SettingsProvider settings={settings}>
          <App/>
        </SettingsProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )

  })
  .catch(error => {
    console.debug(error)
    alert('Failed to initialize app')
    // TODO error screen
    window.location.reload()
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
