import React from 'react'
import cc from 'classcat'
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

export interface FormInputProps {
	register: UseFormRegisterReturn
	errors: FieldErrors,
	label: string
	type: React.InputHTMLAttributes<HTMLInputElement>['type']
	autoFocus?: React.InputHTMLAttributes<HTMLInputElement>['autoFocus']
	autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete']
	disabled?: React.InputHTMLAttributes<HTMLInputElement>['disabled']
}

export const FormInput: React.FC<FormInputProps> = ({ label, type, autoFocus = false, register, errors, disabled, autoComplete = 'off' }) => {
	return (
		<div className='Input'>
			<label className='FormLabel'>{label}</label>
			<input 
				className={cc([
					'FormInput',
				])}
				type={type}
				title={label}
				autoFocus={autoFocus}
				autoComplete={autoComplete}
				disabled={disabled}
				{...register}
			/>
			<ErrorMessage
				name={register.name}
				errors={errors}
				render={({ message }) => <span className='FormError'>{message}</span>}
			/>
		</div>
	)
}
