import React, { useEffect } from 'react'
import { AppTitle } from '../components/AppTitle'
import { Loader } from '../components/Loader'
import { useUser } from '../context/user'

export const LoginPage: React.FC = () => {
  const { initialized, logged, googleLoginRedirect } = useUser()
  
  useEffect(() => {
    if (initialized && !logged) {
      // TODO prevent redirect hell ()
      googleLoginRedirect()
    }
  }, [initialized, logged, googleLoginRedirect])

  return (
    <>
      <AppTitle/>
      <Loader reason='Redirecting to login page'/>
    </>
  )
}
