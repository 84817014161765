import { AppProviders } from './context/app'
import Router from './Router'

function App(): JSX.Element {
  return (
    <AppProviders>
      <Router/>
    </AppProviders>
  )
}

export default App
