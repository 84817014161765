import cc from 'classcat'

export interface ButtonProps {
	href: HTMLAnchorElement['href'],
	children: string,
	target?: HTMLAnchorElement['target'],
}

export const ExternalLink: React.FC<ButtonProps> = ({ href, target = '_blank', children }) => {
	return (
		<a
			className={cc([
				'Link',
			])}
			href={href}
			target={target}
		>
			{children}
		</a>
	)
}
