import React, { useEffect } from 'react'
import { APP_TITLE } from '../constants'

export interface Props {
	subtitle?: string
}

export const AppTitle: React.FC<Props> = ({ subtitle }) => {
	useEffect(() => {
		let title = APP_TITLE
		if (subtitle) {
			title += ` - ${subtitle}`
		}
		document.title = title
	}, [subtitle])

	return null
}
